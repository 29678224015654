<i18n>
{
  "en": {
		"noItemsText": "No news to show."
	},
  "fi": {
		"noItemsText": "Uutisia ei löytynyt."
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view :key="$route.fullPath" />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'newsContainer'">
			<v-toolbar
				:color="this.$vuetify.theme.dark ? null : 'primary'"
				dark
				flat
			>
				<v-tabs
					v-model="currentTab"
					centered
					center-active
					show-arrows
					icons-and-text
				>
					<v-tabs-slider />
					<v-tab
						v-for="(category) in categories"
						:key="category.id"
					>
						{{ category.title }}
						<v-icon v-if="category.icon">
							{{ category.icon }}
						</v-icon>
					</v-tab>
				</v-tabs>
			</v-toolbar>
			<v-tabs-items v-model="currentTab">
				<v-tab-item
					v-for="(category) in categories"
					:key="category.id"
				>
					<v-container class="container--narrow">
						<NewsList :items="filteredArticles(category.id)" />
					</v-container>
				</v-tab-item>
			</v-tabs-items>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'NewsContainer',
	data: () => ({
		currentTab: 0,
	}),
	computed: {
		...mapState({
			categories: state => state.news.categories,
			articles: state => state.news.articles,
		}),
	},
	mounted () {
		this.$api.News.doRequest()
	},
	methods: {
		filteredArticles (categoryId) {
			if (categoryId === 0) return this.articles.filter(article => !article.article_category.includes(5323))
			return this.articles.filter(article => article.article_category.includes(categoryId))
		},
	},
}
</script>
