<template>
	<FilterableList
		:items="items"
		subtitle-src="startDate"
		summary-src="location"
		:enable-search="false"
		:no-items-text="noItemsText"
		@itemClick="item => $emit('itemClick', item)"
	>
		<template #content="{ item }">
			<v-list-item-title
				class="v-list-item__title--multiline"
			>
				<template
					v-if="item.resort || item.preferredResort"
				>
					<template
						v-if="item.startDate || item.preferredStartDate"
					>
						{{ item.startDate || item.preferredStartDate }}
						<template
							v-if="item.startDate && item.endDate && item.endDate !== item.startDate"
						>
							- {{ item.endDate }}
						</template>
						<template
							v-else-if="!item.startDate"
						>
							→
						</template>
					</template>
				</template>
				<template
					v-else
				>
					{{ item.title }}
				</template>
			</v-list-item-title>
			<v-list-item-subtitle
				v-if="item.resort || item.preferredResort"
				class="v-list-item__subtitle--multiline"
			>
				{{ item.resort || item.preferredResort }}
			</v-list-item-subtitle>
		</template>
	</FilterableList>
</template>

<script>

export default {
	name: 'ResortApplicationList',
	props: {
		items: {
			type: [Array],
			required: false,
			default () {
				return []
			},
		},
		noItemsText: {
			type: String,
			required: false,
			default: () => {
				return null
			},
		},
	},
}

</script>
