<i18n>
{
	"en": {
		"card": {
			"entityNumber": "Membership number",
			"editYourDetails": "Edit your details"
		},
		"news": {
			"title": "News",
			"allNewsButtonText": "Show all news",
			"noItemsText": "No news to show."
		},
		"events": {
			"title": "Events",
			"allEventsButtonText": "Show all events",
			"noItemsText": "No events to show."
		}
	},
	"fi": {
		"card": {
			"entityNumber": "Jäsennumero",
			"editYourDetails": "Päivitä tietojasi rauasioinnissa"
		},
		"news": {
			"title": "Uutiset",
			"allNewsButtonText": "Näytä kaikki uutiset",
			"noItemsText": "Uutisia ei löytynyt"
		},
		"events": {
			"title": "Tapahtumat",
			"allEventsButtonText": "Näytä kaikki tapahtumat",
			"noItemsText": "Tapahtumia ei löytynyt"
		}
	}
}
</i18n>

<template>
	<div>
		<!-- Membership card -->
		<v-container class="container--narrow">
			<v-card>
				<v-card-title class="headline">
					{{ user._full_name }}
				</v-card-title>
				<v-card-subtitle
					v-if="user.entity_number"
				>
					{{ $i18n.t('card.entityNumber') }}: {{ user.entity_number }}
				</v-card-subtitle>
			</v-card>
		</v-container>

		<!-- News -->
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>
					{{ $i18n.t('news.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<NewsList
						:items="filteredNews()"
						:enable-search="false"
						:max-items="4"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						:to="{ name: 'newsContainer' }"
						color="primary"
						text
					>
						<v-icon left>
							mdi-bullhorn
						</v-icon>
						{{ $i18n.t('news.allNewsButtonText') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>

		<!-- Events -->
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>
					{{ $i18n.t('events.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<FilterableList
						:items="events"
						subtitle-src="_event_dates"
						:enable-search="false"
						:max-items="4"
						:meta-src="null"
						redirect-url-src="link"
						icon-color-src="_icon_color"
						:no-items-text="$i18n.t('events.noItemsText')"
						@itemClick="item => $router.push({ name: 'event', params: { pagename: item.name } })"
					>
						<template #title="slotProps">
							{{ slotProps.item.title }}

							<v-chip
								v-if="slotProps.item.badge"
								color="secondary"
								small
								class="ml-2"
							>
								{{ slotProps.item.badge }}
							</v-chip>
						</template>
					</FilterableList>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						:to="{ name: 'eventContainer' }"
						color="primary"
						text
					>
						<v-icon left>
							mdi-calendar
						</v-icon>
						{{ $i18n.t('events.allEventsButtonText') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MembershipCard',
	computed: {
		...mapState({
			config: state => state.config,
			user: state => state.user,
			news: state => state.news.articles,
			events: state => state.events.items,
		}),
	},
	mounted () {
		this.$api.Me.doRequest()
	},
	methods: {
		filteredNews () {
			return this.news.filter(article => !article.article_category.includes(5323))
		},
	},
}
</script>
