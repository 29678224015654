<i18n>
{
  "en": {
		"noItemsText": "No events to show."
	},
  "fi": {
		"noItemsText": "Tapahtumia ei löytynyt."
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view :key="$route.fullPath" />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'eventContainer'">
			<v-toolbar
				:color="this.$vuetify.theme.dark ? null : 'primary'"
				dark
				flat
			>
				<v-tabs
					v-model="currentTab"
					centered
					center-active
					show-arrows
					icons-and-text
				>
					<v-tabs-slider />
					<v-tab
						v-for="(category) in categories"
						:key="category.id"
					>
						{{ category.title }}
						<v-icon v-if="category.icon">
							{{ category.icon }}
						</v-icon>
					</v-tab>
				</v-tabs>
			</v-toolbar>
			<v-tabs-items v-model="currentTab">
				<v-tab-item
					v-for="(category) in categories"
					:key="category.id"
				>
					<v-container class="container--narrow">
						<FilterableList
							:items="filteredEvents(category.id)"
							subtitle-src="_event_dates"
							:meta-src="null"
							redirect-url-src="link"
							:no-items-text="$i18n.t('noItemsText')"
							@itemClick="item => $router.push({ name: 'event', params: { pagename: item.name } })"
						>
							<template #title="slotProps">
								{{ slotProps.item.title }}

								<v-chip
									v-if="slotProps.item.badge"
									color="secondary"
									small
									class="ml-2"
								>
									{{ slotProps.item.badge }}
								</v-chip>
							</template>
						</FilterableList>
					</v-container>
				</v-tab-item>
			</v-tabs-items>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'EventContainer',
	data: () => ({
		currentTab: 0,
	}),
	computed: {
		...mapState({
			categories: state => state.events.categories,
			items: state => state.events.items,
		}),
	},
	mounted () {
		this.$api.Events.doRequest()
	},
	methods: {
		filteredEvents (categoryId) {
			if (categoryId === 0) return this.items
			return this.items.filter(item => item.event_category === categoryId)
		},
	},
}
</script>
