<i18n>
{
  "en": {
		"info": {
			"title": "Resort details",
			"showImages": "Show images"
		},
		"equipment": {
			"title": "Equipment"
		},
		"location": {
			"title": "Location",
			"showMap": "Show map"
		},
		"prices": {
			"title": "Prices"
		},
		"contact": {
			"title": "Contacts"
		},
		"instructions": {
			"title": "Instructions for vacation"
		},
		"images": {
			"title": "Photos"
		}
	},
  "fi": {
		"info": {
			"title": "Kohdetiedot",
			"showImages": "Näytä kuvat"
		},
		"equipment": {
			"title": "Varustelu"
		},
		"location": {
			"title": "Sijainti",
			"showMap": "Näytä kartta"
		},
		"prices": {
			"title": "Hinnat"
		},
		"contact": {
			"title": "Yhteystiedot"
		},
		"instructions": {
			"title": "Ohjeita lomailuun"
		},
		"images": {
			"title": "Kuvat"
		}
	}
}
</i18n>

<template>
	<div v-if="item">
		<v-container
			v-if="item.summary"
			class="container--narrow"
		>
			<v-card>
				<v-img
					v-if="item.image"
					class="white--text align-end"
					aspect-ratio="1.628"
					:src="item.image.url"
					gradient="rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, .6) 100%"
					dark
				>
					<v-card-title class="headline">
						{{ item.title }}
					</v-card-title>
				</v-img>
				<v-card-title v-else>
					{{ item.title }}
				</v-card-title>
				<v-divider />
				<v-card-text
					v-if="item.summary"
					v-html="item.summary"
				/>
				<template v-if="item.resort_details && item.resort_details.length">
					<v-card-text
						class="px-0"
					>
						<v-expansion-panels flat>
							<v-expansion-panel
								v-for="detail of item.resort_details"
								:key="detail.id"
							>
								<v-expansion-panel-header>
									{{ detail.title }}
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-card-text
										class="pa-0"
										v-html="detail.content"
									/>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-card-text>
				</template>
				<template v-if="item.images && item.images.length">
					<v-divider />
					<v-card-actions>
						<v-btn
							target="_blank"
							color="primary"
							text
							@click="$vuetify.goTo($refs.images)"
						>
							<v-icon left>
								mdi-image-multiple
							</v-icon>
							{{ $i18n.t('info.showImages') }}
						</v-btn>
					</v-card-actions>
				</template>
			</v-card>
		</v-container>

		<v-container
			v-if="item.equipment_groups && item.equipment_groups.length"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					<v-icon
						left
						color="primary"
						class="mr-3"
					>
						mdi-star
					</v-icon>
					{{ $i18n.t('equipment.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text class="px-0">
					<v-expansion-panels flat>
						<v-expansion-panel
							v-for="group of item.equipment_groups"
							:key="group.id"
						>
							<v-expansion-panel-header>
								<v-icon
									v-if="group.icon"
									style="flex: none;"
									color="primary"
									class="mr-3"
								>
									{{ group.icon }}
								</v-icon>
								<div>{{ group.title }}</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<FilterableList
									:list-props="{ dense: true }"
									:items="group.equipment_items"
									:enable-search="false"
									:enable-click="false"
									:multiline="true"
									icon-src="_list_icon"
									title-src="_list_title"
									summary-src="summary_text"
								/>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-card-text>
			</v-card>
		</v-container>

		<v-container
			v-if="item.address || item.postal || item.city || item.map_link || item.resort_location_details"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					<v-icon
						left
						color="primary"
						class="mr-3"
					>
						mdi-map-marker
					</v-icon>
					{{ $i18n.t('location.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text v-if="item.location_information || (item.resort_location_details && item.resort_location_details.length)">
					<div
						v-if="item.location_information"
						v-html="item.location_information"
					/>
					<FilterableList
						v-if="item.resort_location_details"
						:list-props="{ dense: true }"
						:items="item.resort_location_details"
						:enable-search="false"
						:enable-click="false"
						:multiline="true"
						icon-src="icon"
						title-src="title"
						summary-src="summary_text"
					/>
				</v-card-text>
				<template v-if="item.map_link">
					<v-divider />
					<v-card-actions>
						<v-btn
							:href="item.map_link"
							target="_blank"
							color="primary"
							text
						>
							<v-icon left>
								mdi-map
							</v-icon>
							{{ $i18n.t('location.showMap') }}
						</v-btn>
					</v-card-actions>
				</template>
			</v-card>
		</v-container>

		<v-container
			v-if="item.prices_details || (item.prices && item.prices.length)"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					<v-icon
						left
						color="primary"
						class="mr-3"
					>
						mdi-currency-eur
					</v-icon>
					{{ $i18n.t('prices.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<div v-html="item.prices_details" />
					<FilterableList
						v-if="item.prices.length"
						:list-props="{ dense: true }"
						:items="item.prices"
						icon-src="_icon"
						subtitle-src="_title"
						title-src="_price_formatted"
						:enable-search="false"
						:enable-click="false"
						:multiline="true"
					/>
				</v-card-text>
			</v-card>
		</v-container>

		<v-container
			v-if="item.resort_contact_details && item.resort_contact_details.length"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					<v-icon
						left
						color="primary"
						class="mr-3"
					>
						mdi-phone
					</v-icon>
					{{ $i18n.t('contact.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<FilterableList
						:items="item.resort_contact_details"
						:enable-click="false"
						:enable-search="false"
						:multiline="true"
						subtitle-src="title"
						title-src="contact_name"
						summary-src="_list_summary"
					>
						<template #content="slotProps">
							<v-list-item-subtitle
								v-if="slotProps.item.title"
								class="v-list-item__subtitle--multiline"
								v-html="slotProps.item.title"
							/>
							<v-list-item-title
								v-if="slotProps.item.contact_name"
								class="v-list-item__subtitle--multiline"
								v-html="slotProps.item.contact_name"
							/>
							<v-list-item-subtitle
								v-if="slotProps.item._list_summary"
								class="v-list-item__subtitle--multiline"
								v-html="slotProps.item._list_summary"
							/>
							<div class="mt-3">
								<v-btn
									v-if="slotProps.item._tel_link"
									icon
									rounded
									:href="slotProps.item._tel_link"
								>
									<v-icon color="primary">
										mdi-phone
									</v-icon>
								</v-btn>
								<v-btn
									v-if="slotProps.item._email_link"
									icon
									rounded
									:href="slotProps.item._email_link"
								>
									<v-icon color="primary">
										mdi-email
									</v-icon>
								</v-btn>
								<v-btn
									v-if="slotProps.item.link"
									icon
									rounded
									:href="slotProps.item.link"
									target="_blank"
								>
									<v-icon color="primary">
										mdi-home
									</v-icon>
								</v-btn>
							</div>
						</template>
					</FilterableList>
				</v-card-text>
			</v-card>
		</v-container>

		<v-container
			v-if="item.vacation_instructions && item.vacation_instructions.length"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					<v-icon
						left
						color="primary"
						class="mr-3"
					>
						mdi-star
					</v-icon>
					{{ $i18n.t('instructions.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text
					class="px-0"
				>
					<v-expansion-panels flat>
						<v-expansion-panel
							v-for="instruction of item.vacation_instructions"
							:key="instruction.id"
						>
							<v-expansion-panel-header>
								{{ instruction.title }}
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-card-text class="pa-0">
									<div
										v-if="instruction.content"
										v-html="instruction.content"
									/>
									<FilterableList
										v-if="instruction.files.length"
										:items="instruction.files"
										:enable-search="false"
										title-src="list_title"
										summary-src="list_summary"
										redirect-url-src="url"
									/>
								</v-card-text>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-card-text>
			</v-card>
		</v-container>

		<v-container
			v-if="item.images && item.images.length"
			ref="images"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					<v-icon
						left
						color="primary"
						class="mr-3"
					>
						mdi-image-multiple
					</v-icon>
					{{ $i18n.t('images.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<v-row
						align-content="start"
						align="start"
					>
						<v-col
							v-for="(image, imageIndex) of item.images"
							:key="imageIndex"
							cols="4"
							sm="3"
						>
							<v-btn
								block
								height="auto"
								class="pa-0"
								@click="openImage(image)"
							>
								<v-img
									aspect-ratio="1"
									:src="image.url"
								/>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>

				<v-dialog
					v-model="imageDialogOpen"
					:max-width="imageDialogWidth"
				>
					<v-card
						v-if="selectedImage && selectedImage.url"
						@click="imageDialogOpen = false"
					>
						<v-img
							:src="selectedImage.url"
							width="100%"
							:aspect-ratio="imageAspectRatio"
						/>
						<template v-if="selectedImage.description">
							<v-card-text class="mt-3">
								{{ selectedImage.description }}
							</v-card-text>
						</template>
					</v-card>
				</v-dialog>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Resort',
	data: () => ({
		item: null,
		imageDialogOpen: false,
		selectedImage: null,
	}),
	computed: {
		...mapState({
			items: state => state.resortPresentations,
		}),
		imageDialogWidth () {
			if (!this.selectedImage) return 640

			return this.selectedImage.width <= 640 ? this.selectedImage.width : 640
		},
		imageAspectRatio () {
			if (!this.selectedImage) return 1

			return this.selectedImage.width / this.selectedImage.height
		},
	},
	mounted () {
		// Find current item from the store
		this.item = this.items.find(item => {
			return item.name == this.$route.params.pagename
		})

		if (!this.item) {
			this.$router.replace({ name: 'error404' })
		}

		// Set custom headline
		this.$nextTick(() => {
			this.$root.$emit('setHeadline', this.item.title)
		})
	},
	methods: {
		openImage (item) {
			this.selectedImage = item

			this.$nextTick(() => {
				this.imageDialogOpen = true
			})
		},
	},
}
</script>
