
<i18n>
{
  "en": {
		"buttons": {
			"browseResorts": "Browse resorts",
			"info": "Information about reservations",
			"myReservations": "My reservations"
		}
	},
  "fi": {
		"buttons": {
			"browseResorts": "Tutustu lomapaikkoihin",
			"info": "Lomahaun ohjeet",
			"myReservations": "Lomapaikkahakemukset"
		}
	}
}
</i18n>

<template>
	<div>
		<!-- Output child views -->
		<router-view />

		<template v-if="$route.name === 'resorts'">
			<v-container class="container--narrow">
				<v-btn
					block
					large
					color="primary"
					:to="({ name: 'resortContainer' })"
				>
					<v-icon left>
						mdi-home-group
					</v-icon>
					{{ $i18n.t('buttons.browseResorts') }}
				</v-btn>
			</v-container>
			<v-container class="container--narrow">
				<v-btn
					block
					large
					color="primary"
					:to="({ name: 'resortsInfo' })"
				>
					<v-icon left>
						mdi-send
					</v-icon>
					{{ $i18n.t('buttons.info') }}
				</v-btn>
			</v-container>
			<v-container class="container--narrow">
				<v-btn
					block
					large
					color="primary"
					:to="({ name: 'resortReservations' })"
				>
					<v-icon left>
						mdi-send
					</v-icon>
					{{ $i18n.t('buttons.myReservations') }}
				</v-btn>
			</v-container>
			<v-container
				v-if="data.info_text"
				class="container--narrow"
			>
				<v-card>
					<v-card-text>
						<RichText :content="data.info_text" />
					</v-card-text>
				</v-card>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Resorts',
	computed: {
		...mapState({
			data: state => state.resortsData,
			user: state => state.user,
		}),
	},
	mounted () {
		this.$api.Resorts.doRequest()
	},
}
</script>
