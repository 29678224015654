
<i18n>
{
  "en": {
		"noItemsText": "No resorts to show",
		"buttons": {
			"myReservations": "My reservations",
			"browseResorts": "Browse resorts"
		}
	},
  "fi": {
		"noItemsText": "Ei näytettäviä lomapaikkoja",
		"buttons": {
			"myReservations": "Omat lomapaikkahakemukset",
			"browseResorts": "Selaa lomapaikkoja"
		}
	}
}
</i18n>

<template>
	<div>
		<v-container
			v-if="ready === false"
			class="container--narrow"
		>
			<Spinner />
		</v-container>
		<template v-else>
			<!-- Output child views -->
			<router-view />
			<v-container
				v-if="$route.name === 'resortContainer'"
				class="container--narrow"
			>
				<template v-if="items && items.length">
					<v-row>
						<v-col
							v-for="item of items"
							:key="item.id"
							cols="12"
							sm="6"
						>
							<v-card @click="$router.push({ name: 'resort', params: { pagename: item.name }})">
								<v-img
									v-if="item.image"
									class="white--text align-end"
									aspect-ratio="1.628"
									:src="item.image.url"
									gradient="rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, .6) 100%"
									dark
								>
									<v-card-title>
										{{ item.title }}
									</v-card-title>
								</v-img>
								<v-card-title
									v-else
								>
									{{ item.title }}
								</v-card-title>
							</v-card>
						</v-col>
					</v-row>
				</template>
				<v-alert
					v-else
					type="info"
				>
					{{ $i18n.t('noItemsText') }}
				</v-alert>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'ResortContainer',
	data: () => ({
		ready: false,
	}),
	computed: {
		...mapState({
			items: state => state.resortPresentations,
		}),
	},
	async mounted () {
		await this.$api.ResortPresentations.doRequest()
		await this.$nextTick()

		this.ready = true
	},
}
</script>
