<i18n>
	{
		"en": {
			"inputLabel": "Search",
			"noItemsText": "No items to show.",
			"noResultsText": "Your search didn't match any results."
		},
		"fi": {
			"inputLabel": "Haku",
			"noItemsText": "Ei näytettäviä kohteita.",
			"noResultsText": "Ei hakutuloksia."
		}
	}
</i18n>

<template>
	<FilterableList
		:items="items"
		:enable-search="enableSearch"
		:max-items="maxItems"
		subtitle-src="date"
		:meta-src="null"
		redirect-url-src="link"
		:no-items-text="$i18n.t('noItemsText')"
		@itemClick="item => $router.push({ name: 'news', params: { pagename: item.name } })"
	>
		<template #icon="slotProps">
			<v-badge
				dot
				color="secondary"
				v-if="slotProps.item._is_read === false"
			>
				<v-icon :color="slotProps.item._icon_color">
					{{ slotProps.item.icon }}
				</v-icon>
			</v-badge>
			<v-icon
				v-else
				:color="slotProps.item._icon_color"
			>
				{{ slotProps.item.icon }}
			</v-icon>
		</template>
		<template #title="slotProps">
			{{ slotProps.item.title }}
			<v-chip
				v-if="slotProps.item.badge"
				color="secondary"
				small
				class="ml-2"
			>
				{{ slotProps.item.badge }}
			</v-chip>
		</template>
	</FilterableList>
</template>

<script>

export default {
	name: 'NewsList',

	props: {
		items: {
			type: [Array],
			required: false,
			default () {
				return []
			},
		},
		// Maximum number of items to render
		maxItems: {
			type: Number,
			required: false,
			default: () => {
				return null
			},
		},
		// Enable search?
		enableSearch: {
			type: Boolean,
			required: false,
			default: () => {
				return true
			},
		},

		// Item property to use for item icon color
		iconColorSrc: {
			type: String,
			required: false,
			default: () => {
				return 'icon_color'
			},
		},

	},
}

</script>
