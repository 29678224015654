<template>
	<div class="resortsinfo">
		<v-container class="container--narrow">
			<RichText :content="data.info_detailed" />
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'ResortsInfo',
	computed: {
		...mapState({
			data: state => state.resortsData,
		}),
	},
	mounted () {
		this.$api.Resorts.doRequest()
	},
}

</script>
