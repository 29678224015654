<i18n>
{
  "en": {
		"titles": {
			"front": "This application uses cookies",
			"chooseCookies": "Choose cookie types to use",
			"aboutCookies": "About cookies"
		},
		"description": "This application uses essential cookies that are necessary for the operation of the application, as well as cookies used for anonymous statistical purposes, or to display personalized content. Some parts of the content may also depend on marketing cookies. You can change your selection later in the settings page.",
		"buttons": {
			"readMore": "Read more about cookies",
			"chooseCookies": "Choose cookies",
			"acceptNecessary": "Accept only necessary",
			"acceptAll": "Accept all",
			"acceptChosen": "Accept chosen"
		},
		"messages": {
			"saved": "Your cookie settings have been saved"
		}
	},
	"fi": {
		"titles": {
			"front": "Tämä sovellus käyttää evästeitä",
			"chooseCookies": "Valitse käytettävät evästeet",
			"aboutCookies": "Tietoa evästeistä"
		},
		"description": "Tämä sovellus hyödyntää toiminnan kannalta välttämättömiä evästeitä sekä sovelluksen kehittämisen mahdollistavia tilastointievästeitä. Joidenkin sisältöjen näyttäminen voi lisäksi edellyttää markkinointievästeiden hyväksymistä. Voit myöhemmin muuttaa valintaasi asetukset-sivulla.",
		"buttons": {
			"readMore": "Lue lisää evästeistä",
			"chooseCookies": "Valitse evästeet",
			"acceptNecessary": "Hyväksy vain välttämättömät",
			"acceptAll": "Hyväksy kaikki",
			"acceptChosen": "Hyväksy valitut"
		},
		"messages": {
			"saved": "Evästeasetuksesi on tallennettu"
		}
	}
}
</i18n>

<template>
	<v-card>
		<!-- Choose cookie types -->
		<template v-if="page === 'chooseCookies'">
			<v-card-title class="text-h6">
				{{ $i18n.t('titles.chooseCookies') }}
			</v-card-title>
			<v-card-text>
				<v-checkbox
					v-for="(item, index) in cookieTypes"
					:key="'cookietype-' + index"
					v-model="acceptedCookieTypes"
					:label="$i18n.t('cookieTypes.' + item)"
					:value="item"
					:disabled="item === 'necessary'"
					:hide-details="true"
					dense
				/>
			</v-card-text>
			<v-divider />
			<v-card-text>
				<v-btn
					color="primary"
					block
					small
					@click="consent(acceptedCookieTypes)"
				>
					{{ $i18n.t('buttons.acceptChosen') }}
				</v-btn>
				<v-btn
					color="primary"
					outlined
					dark
					block
					small
					class="mt-3"
					@click="page = null"
				>
					{{ $i18n.t('general.goBack') }}
				</v-btn>
			</v-card-text>
		</template>

		<!-- About cookies page -->
		<template v-else-if="page === 'aboutCookies' && cookieInfoPage">
			<v-card-title class="text-h6">
				{{ cookieInfoPage.title }}
			</v-card-title>
			<v-card-text>
				<ContentBlocks :items="cookieInfoPage.content_blocks" />
			</v-card-text>
			<v-card-actions>
				<v-btn
					color="primary"
					dark
					block
					small
					class="my-3"
					@click="page = null"
				>
					{{ $i18n.t('general.goBack') }}
				</v-btn>
			</v-card-actions>
		</template>

		<!-- Front page -->
		<template v-else>
			<v-card-title class="text-h6">
				{{ $i18n.t('titles.front') }}
			</v-card-title>
			<v-card-text>
				<p class="mb-0">
					{{ $i18n.t('description') }}
					<template
						v-if="cookieInfoPage && cookieInfoPage.id"
					>
						<a
							href="#"
							@click.prevent="page = 'aboutCookies'"
						>
							{{ $i18n.t('buttons.readMore') }}
						</a>.
					</template>
				</p>
			</v-card-text>
			<v-divider />
			<v-card-text>
				<v-btn
					color="primary"
					outlined
					dark
					block
					small
					@click="page = 'chooseCookies'"
				>
					{{ $i18n.t('buttons.chooseCookies') }}
				</v-btn>
				<v-btn
					color="primary"
					outlined
					dark
					block
					small
					class="mt-3"
					@click="consent('necessary')"
				>
					{{ $i18n.t('buttons.acceptNecessary') }}
				</v-btn>
				<v-btn
					color="primary"
					dark
					block
					small
					class="mt-3"
					@click="consent('all')"
				>
					{{ $i18n.t('buttons.acceptAll') }}
				</v-btn>
			</v-card-text>
		</template>
	</v-card>
</template>

<script>

import { mapState } from 'vuex'
import cookieTypes from '@/utils/cookieTypes'

export default {
	name: 'CookieDialog',
	data: () => ({
		page: null,
		cookieTypes: cookieTypes,
		acceptedCookieTypes: [
			'necessary',
		],
	}),
	computed: {
		...mapState([
			'config',
			'settings',
		]),
		cookieInfoPage () {
			return this.config.infoPages.find(item => item.id === 1145)
		},
	},
	mounted () {
		// Get currently accepted cookie types
		this.acceptedCookieTypes = this.settings.acceptedCookies
	},
	methods: {
		consent (type = null) {
			if (!type) return

			// Accept all cookies
			if (typeof type === 'string') {
				if (type === 'all') {
					this.acceptedCookieTypes = this.cookieTypes
				} else if (type === 'necessary') {
					this.acceptedCookieTypes = ['necessary']
				} else {
					return
				}
			}

			if (!Array.isArray(this.acceptedCookieTypes)) return

			// Just in case, make sure that 'necessary' is included in chosen cookie types
			if (!this.acceptedCookieTypes.includes('necessary')) {
				this.acceptedCookieTypes.unshift('necessary')
			}

			this.$store.dispatch('setNotifyMessage', this.$i18n.t('messages.saved'))
			this.$emit('consent', this.acceptedCookieTypes)
		},
	},
}

</script>

<style lang="scss" scoped>

</style>
