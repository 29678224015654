<i18n>
{
  "en": {
		"details": {
			"noItemsText": "No details to show."
		},
		"documents": {
			"title": "Documents"
		}
	},
  "fi": {
		"details": {
			"noItemsText": "Ei näytettäviä tietoja."
		},
		"documents": {
			"title": "Dokumentit"
		}
	}
}
</i18n>

<template>
	<div>
		<v-container class="container--narrow">
			<v-card>
				<v-card-text>
					<FilterableList
						:items="user._collective_agreement.details"
						subtitle-src="label"
						title-src="value"
						:enable-search="false"
						:enable-click="false"
						:no-items-text="$i18n.t('details.noItemsText')"
					/>
				</v-card-text>
				<template v-if="user._collective_agreement.summary">
					<v-divider />
					<v-card-text v-html="user._collective_agreement.summary" />
				</template>
			</v-card>
		</v-container>
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>
					{{ $i18n.t('documents.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<FilterableList
						:items="user._collective_agreement.files"
						:enable-search="false"
						title-src="list_title"
						summary-src="list_summary"
						redirect-url-src="url"
					/>
				</v-card-text>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'CollectiveAgreement',
	computed: {
		...mapState({
			user: state => state.user,
		}),
	},
	mounted () {
		this.$api.Me.doRequest()
	},
}
</script>
