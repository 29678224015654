<template>
	<div ref="wrap">
		<div
			v-for="(item, index) in items"
			:key="item.id"
			:class="[
				index > 0 ? 'pt-6' : null,
				index < items.length -1 ? 'pb-6' : null,
			]"
		>
			<RichText
				v-if="item.type === 'text_content_block' && item.content"
				class="rte"
				:content="item.content"
			/>
			<template v-else-if="item.type === 'image_block' && item.image">
				<component
					:is="item.link ? 'a' : 'div'"
					:href="item.link || null"
					:target="item.link ? '_blank' : null"
				>
					<v-img
						:src="item.image.url"
						:alt="item.image.alt"
					/>
				</component>
				<p
					v-if="item.title"
					class="text-body-2 text-center mt-3"
				>
					{{ item.title }}
				</p>
			</template>
			<template v-else-if="item.type === 'video_block' && item.video_link">
				<CookieContent type="marketing">
					<div v-html="item.video_link" />
				</CookieContent>
			</template>
			<template v-else-if="item.type === 'files_block' && item.files.length">
				<FilterableList
					:items="item.files"
					:enable-search="false"
					title-src="list_title"
					summary-src="list_summary"
					redirect-url-src="url"
				/>
			</template>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ContentBlocks',
	props: {
		items: {
			type: [Array],
			required: false,
			default () {
				return []
			},
		},
	},
}
</script>
