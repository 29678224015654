import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		dark: false,
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#95c11f',
				secondary: '#e72229',
				accent: '#2ba6df',
				error: '#e72229',
				info: '#2ba6df',
				success: '#82be42',
				warning: '#ffc107',
			},
			dark: {
				primary: '#b6d588',
				secondary: '#f18165',
				accent: '#89c7eb',
				error: '#e72229',
				info: '#2ba6df',
				success: '#82be42',
				warning: '#ffc107',
			},
		},
	},
	icons: {
		iconfont: 'mdi',
	},
})
